
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faBirthdayCake } from "@fortawesome/pro-solid-svg-icons/faBirthdayCake";
import { faEdit } from "@fortawesome/pro-solid-svg-icons/faEdit";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faHome } from "@fortawesome/pro-solid-svg-icons/faHome";
import { faIdCard } from "@fortawesome/pro-solid-svg-icons/faIdCard";
import { faMobile } from "@fortawesome/pro-solid-svg-icons/faMobile";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Panel } from "appex-theme/src/Core/Panel/Panel";
import { DisplayItem } from "appex-theme/src/Data/DisplayItem/DisplayItem";
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AbstractComponent } from "../../../../component/AbstractComponent";
import { PageBar } from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import { Breadcrumbs } from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import { Member } from "../../../../data/Member/Entity/Member";
import { Organisation } from "../../../../data/Organisation/Entity/Organisation";
import { OrganisationStore } from "../../../../data/Organisation/OrganisationStore";
import { User } from "../../../../data/User/Entity/User";
import { UserStore } from "../../../../data/User/UserStore";
import './scss/ViewMyAccountPage.scss';
import { Transaction } from "../../../../data/Transaction/Entity/Transaction";
import { Money } from "appex-theme/src/Utility/Money";
import { MemberStore } from "../../../../data/Member/MemberStore";
import { DataTable } from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import { DisplayColumn } from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import { Dates } from "appex-theme/src/Utility/Dates";
import { CtaPlaceholder } from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";
import { faExchangeAlt } from "@fortawesome/pro-duotone-svg-icons/faExchangeAlt";
import { TransactionStore } from "../../../../data/Transaction/TransactionStore";

export interface ViewMyAccountPageProps {}
export interface ViewMyAccountPageState {
  user: User,
  updating: boolean,
  organisations: Array<Organisation>,
  userOrgId: number,
  activeOrganisation: Organisation,
  transactions: Array<Transaction>,
  transactionsUpdating: boolean,
  member: Member
}

export class ViewMyAccountPage extends AbstractComponent<ViewMyAccountPageProps, ViewMyAccountPageState>
{
  public constructor(props: ViewMyAccountPageProps)
  {
    super(props);
    
    const userOrgId = OrganisationStore.getActiveOrganisation().userOrgId;
    
    this.state = {
      user: UserStore.getUser(),
      updating: UserStore.isUpdating(),
      organisations: OrganisationStore.getOrganisations(),
      activeOrganisation: OrganisationStore.getActiveOrganisation(),
      userOrgId: userOrgId,
      transactions: TransactionStore.getAllByUserOrgId(userOrgId),
      transactionsUpdating: true,
      member: MemberStore.getByUserOrgIdAndActiveOrganisation(userOrgId)
    }
  }
  
  public componentDidMount()
  {
    // Authed User
    this.unsubscribers['user'] = UserStore.subscribeToUser((user) => {
      this.setState({
        user: user
      })
    }, this.state.user);
    
    UserStore.syncUser();
    
    // Organisations
    this.unsubscribers['organisations'] = OrganisationStore.subscribeToOrganisations((organisations) => {
      this.setState({
        organisations: organisations
      })
    }, this.state.organisations);
  
    // Active Org
    this.unsubscribers['active_organisation'] = OrganisationStore.subscribeToActiveOrganisation(
      (activeOrganisation: Organisation) => {
        this.setState({
          activeOrganisation: activeOrganisation
        })
      },
      this.state.activeOrganisation
    );
    
    // Transactions
    this.unsubscribers["transactions"] = TransactionStore.subscribeToTransactionsByUserOrgId(
      this.state.userOrgId,
      (transactions) => {
        this.setState({
          transactions: transactions,
          transactionsUpdating: false
        });
      }, this.state.transactions);
    
    TransactionStore.syncTransactionsByUserOrgId(this.state.userOrgId);
  }
  
  public render()
  {
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'my_account',
        title: 'My Account'
      }
    ];
    
    return <div className={'ViewMyAccountPage'}>
  
      <div className="grid-container">
  
        <PageBar title={'My Account'} rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}/>
    
        <div className="grid-x grid-margin-x">
          <div className="cell large-4">
            {this.getOrganisationsPanel()}
            {this.getPersonalDetailsPanel()}
          </div>
          <div className="cell large-8">
            {this.getTransactionsTablePanel()}
          </div>
        </div>
      </div>
      
    </div>
  }
  
  private getOrganisationsPanel()
  {
    return <Panel
      headerLeft={<div className="Header">My Organisations</div>}
    >
      {
        !this.state.organisations.length
          ? 'No organisations found.'
          : this.state.organisations.map((organisation: Organisation, index) => {
            return <div
              className={'StatsRow cursor--pointer'}
              key={index}
              onClick={OrganisationStore.setActiveOrganisation.bind(undefined, organisation)}
            >
              <div className="StatsRow__Left">
                { organisation.name }
              </div>
              <div className="StatsRow__Right">
                {
                  this.state.activeOrganisation?.id === organisation.id
                    ? <span><FontAwesomeIcon className={'mr-1'} icon={faCheckCircle}/> Active</span>
                    : <FontAwesomeIcon icon={faArrowRight}/>
                }
              </div>
            </div>
          })
      }
    </Panel>
  }
  
  private getPersonalDetailsPanel()
  {
    const user = this.state.user;
    
    return <Panel
      headerLeft={<div className="Header">Personal Details</div>}
      headerRight={<Link to={'/my-account/update'}><FontAwesomeIcon icon={faEdit}/></Link>}
    >
  
      <div className="grid-x">
        <div className="cell">
          <DisplayItem
            icon={<FontAwesomeIcon icon={faIdCard}/>}
            content={Member.getFullPreferredName(user)}
            updating={this.state.updating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faEnvelope}/>}
            content={user.emailAddress}
            updating={this.state.updating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faMobile}/>}
            content={user.mobileContactNumber}
            updating={this.state.updating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faPhone}/>}
            content={user.homeContactNumber}
            updating={this.state.updating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faHome}/>}
            content={
          
              user.address
                ? <div>
                  { user.address.addressLine1 ? <p>{user.address.addressLine1}</p> : null }
                  { user.address.addressLine2 ? <p>{user.address.addressLine2}</p> : null }
                  { user.address.town ? <p>{user.address.town}</p> : null }
                  { user.address.county ? <p>{user.address.county}</p> : null }
                  { user.address.postCode ? <p>{user.address.postCode}</p> : null }
                </div>
                : '-'
            }
            updating={this.state.updating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faBirthdayCake}/>}
            content={ user.dateOfBirth ? this.getDateOfBirthDisplayValue(user.dateOfBirth) : null }
            updating={this.state.updating}
          />
        </div>
      </div>
    </Panel>
  }
  
  protected getTransactionsTablePanel()
  {
    let member = this.state.member;
    
    // Sort them by date, earliest first (the reverse of what we need to display)
    let transactions = this.state.transactions.sort((a: Transaction, b: Transaction) => {
      if (a.date === b.date)
      {
        return a.id - b.id;
      }
      return (new Date(a.date)).valueOf() - (new Date(b.date)).valueOf();
    });
    
    // Use the reverse order to add cumulative total
    // After forEach, the order will have reversed to most recent first which is correct for displaying
    let balance = member?.broughtForwardBalance || 0;
    
    transactions.forEach((transaction: Transaction) => {
      if (transaction.debitAccountId)
      {
        balance += transaction.amount;
      }
      else
      {
        balance -= transaction.amount;
      }
      
      transaction.balance = balance;
    })
    
    return <Panel
      headerLeft={<div className="Header">Transactions</div>}
      headerRight={<div>
        Current Balance: {Money.getFormattedPrice(member ? MemberStore.getBalanceByUserOrgId(member.userOrgId): 0)}
      </div>}
    >
      <DataTable
        data={transactions}
        displayColumns={[
          DisplayColumn.create(
            'Date',
            null,
            (transaction: Transaction) => {
              return Dates.getShortDisplayValue(new Date(transaction.date))
            },
            1
          ),
          DisplayColumn.create(
            'Description',
            null,
            (transaction: Transaction) => {
              return transaction.description
            },
            2
          ),
          DisplayColumn.create(
            'Amount',
            null,
            (transaction: Transaction) => {
              
              let userBeingCredited = Boolean(transaction.debitAccountId);
              let prefix = userBeingCredited ? '+' : '-';
              
              return <div className={'display--width-100 flex--direction-column'}>
                <span className={'display--width-50 mb-2 mt-smo-1'}>{prefix + Money.getFormattedPrice(transaction.amount)}</span>
                <span className={'display--width-50 color--grey2 text--subtext'}>{transaction.balance >= 0 ? '+' : null}{Money.getFormattedPrice(transaction.balance)}</span>
              </div>
            },
            1
          )
        ]}
        searchable={false}
        sortable={false}
        noDataContent={
          <CtaPlaceholder
            icon={faExchangeAlt}
            heading={'No Transactions Found'}
            text={'No transactions associated with your account'}
          />
        }
        paginationControls={true}
        defaultRowsPerPage={5}
        rowsPerPageOptions={[5, 10, 25]}
        dataUpdating={this.state.transactionsUpdating}
      />
    </Panel>
  }
  
  private getDateOfBirthDisplayValue(dateOfBirth: string): string
  {
    if (!dateOfBirth)
    {
      return '-';
    }
    
    let date = new Date(dateOfBirth);
    
    let monthNames = {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December'
    };
    
    // 20 December 2019
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear() + ' (' + this.getAgeFromDate(date) + ' years old)'
  }
  
  private getAgeFromDate(date: Date)
  {
    const ageDifMs = Date.now() - date.getTime();
    const ageDate = new Date(ageDifMs); // milliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}
