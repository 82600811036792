
import * as React from 'react';
import {Link} from 'react-router-dom';
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Event} from '../../../../data/Event/Entity/Event';
import './scss/ViewAllEventsPage.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DateInput} from "appex-theme/src/Form/Input/Date/DateInput";
import {TimeInput} from "appex-theme/src/Form/Input/Time/TimeInput";
import {Button} from "appex-theme/src/Core/Button/Button";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import {ViewAllMembersProps} from "../../Members/ViewAll/ViewAllMembersPage";
import {EventStore} from "../../../../data/Event/EventStore";
import {OrganisationStore} from "../../../../data/Organisation/OrganisationStore";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import {EventAttendanceStore} from "../../../../data/Event/EventAttendanceStore";
import {EventTicketStore} from "../../../../data/Event/EventTicketStore";
import {Attendance} from "../../../../data/Event/Entity/Attendance";
import {EventCard} from "appex-theme/src/Event/EventCard/EventCard";
import {faCheckCircle} from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import {faEnvelopeOpenText} from "@fortawesome/pro-regular-svg-icons/faEnvelopeOpenText";
import {faExclamationTriangle} from "@fortawesome/pro-duotone-svg-icons/faExclamationTriangle";
import {faCalendarAlt} from "@fortawesome/pro-duotone-svg-icons/faCalendarAlt";
import {DeviceDetection} from "appex-theme/src/Utility/DeviceDetection";
import {PaginationControls} from "appex-theme/src/Data/DataTable/PaginationControls/PaginationControls";
import {CtaPlaceholder} from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";
import {EventGuestStore} from "../../../../data/Event/EventGuestStore";

export interface ViewAllEventsProps {}
export interface ViewAllEventsState {
  events: Array<Event>,
  attendanceByEventId: any,
  ticketsByEventId: any,
  searchValue: string,
  rowsPerPage: number,
  rowsPerPageOptions: Array<number>,
  currentPage: number,
  eventsUpdating: boolean
}

export class ViewAllEventsPage extends AbstractComponent<ViewAllEventsProps, ViewAllEventsState>
{
  protected unsubscribers = {};
  protected searchFieldDomNode;

  public constructor(props: ViewAllMembersProps)
  {
    super(props);

    let events = EventStore.getAllEventsByActiveOrganisation();
    let attendanceByEventId = EventAttendanceStore.getAllAttendance();
    let ticketsByEventId = EventTicketStore.getAllTickets();

    this.state = {
      events: events ? events : [],
      attendanceByEventId: attendanceByEventId ? attendanceByEventId : {},
      ticketsByEventId: ticketsByEventId ? ticketsByEventId : {},
      searchValue: '',
      rowsPerPage: 5,
      rowsPerPageOptions: [5, 10],
      currentPage: 1,
      eventsUpdating: true
    };
  }

  public componentDidMount()
  {
    /**
     * Subscribe to Events
     */
    
    this.unsubscribers['events'] = EventStore.subscribeToActiveOrgEvents(
      (events: Array<Event>) => {
        this.setState({
          events: events
        })
      },
      this.state.events
    )

    /**
     * Subscribe to Attendance
     */
    
    this.unsubscribers['events_updating'] = EventStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          eventsUpdating: updating
        })
      },
      this.state.eventsUpdating
    )
    
    this.unsubscribers['all_attendance'] = EventAttendanceStore.subscribeToAllAttendance(
      (attendanceByEventId) => {
        this.setState({
          attendanceByEventId: attendanceByEventId
        })
      },
      this.state.attendanceByEventId
    )
    
    // Sync events from API with redux store
    EventStore.syncActiveOrganisationEvents();

    if (
      !DeviceDetection.isTouchDevice()
      && this.searchFieldDomNode
      && this.state.events.length
    )
    {
      let inputFocusTimeout = setTimeout(() => {
        this.searchFieldDomNode.focus();
        clearTimeout(inputFocusTimeout);
      }, 300);
    }

  }

  public componentWillUnmount()
  {
    if (this.unsubscribers)
    {
      for (let key in this.unsubscribers)
      {
        this.unsubscribers[key]();
      }
    }
  }

  public render()
  {
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'events',
        title: 'Events'
      }
    ];

    let events = this.state.events;

    events = this.applyFilter(events);
    let eventsTotal = events.length;
    events = this.applySort(events);
    events = this.applyPagination(events);

    return <div className={'ViewAllEvents'}>

      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <PageBar
              title={<div><FontAwesomeIcon icon={faCalendarAlt}/>Events</div>}
              rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
            />
            <div className="grid-x grid-x-margin">
              <div className="cell">
                <Panel>
                  { this.getEventsContent(events, eventsTotal) }
                </Panel>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  }

  protected applyFilter(events: Array<Event>): Array<Event>
  {
    return events.filter((item) => {
      return this.onSearch(this.state.searchValue, item)
    });
  }

  protected applySort(events: Array<Event>): Array<Event>
  {
    return events.sort((a: Event, b: Event) => {
      return (new Date(b.start)).valueOf() - (new Date(a.start)).valueOf();
    });
  }

  protected applyPagination(events: Array<Event>): Array<Event>
  {
    let startIndex = this.getCurrentPageStartIndex(events.length);
    let endIndex = this.getCurrentPageEndIndex(events.length);
    events = events.slice(startIndex, endIndex);

    return events;
  }

  protected getCurrentPageStartIndex(dataLength: number): number
  {
    let endIndex = this.getCurrentPageEndIndex(dataLength);
    return (endIndex - this.state.rowsPerPage);
  }

  protected getCurrentPageEndIndex(dataLength: number)
  {
    return this.state.currentPage * this.state.rowsPerPage;
  }

  protected getEventsContent(events: Array<Event>, eventsTotal: number)
  {
    let noResultsMessage = null;

    if (this.state.searchValue && events.length == 0)
    {
      noResultsMessage = <CtaPlaceholder
        icon={faExclamationTriangle}
        heading={'No Results Found'}
        text={'Your search didn\'t return any results'}
      />
    }

    let tableControls = this.getTableControls(eventsTotal);
    let mainContent = null;
    let disabledToolbar = false;

    if (
      this.state.searchValue
      && !events.length
      && !this.state.eventsUpdating
    )
    {
      mainContent = noResultsMessage;
    }
    else if (!eventsTotal && !this.state.eventsUpdating)
    {
      mainContent = this.getNoEventsContent();
    }
    else
    {
      mainContent = this.getEventCards(events)
    }

    if (this.state.eventsUpdating && !events.length)
    {
      // disabledToolbar = true;
    }

    return <div>
      <div className={"Toolbar Toolbar--JustifyFlexEnd mb-2" + (disabledToolbar ? ' Toolbar--Disabled' : '')}>
        <div
          className={[
            'SearchField'
          ].join(' ')}
        >
          <input
            type="text"
            value={this.state.searchValue}
            placeholder={'Search'}
            onChange={this.onSearchChange.bind(this)}
            ref={this.setSearchFieldDomNode.bind(this)}
          />
          <i className="fal fa-search"/>
        </div>

      </div>
      {
        mainContent
      }

      {tableControls}

      {
        PermissionsStore.hasPermission('event:write')
          ? <div className="text--align-center-sm-only text--align-right-md mt-4 mb-3">
              <Link to={'/events/new'}>
                <Button modifiers={['primary']}>
                  Create New Event
                </Button>
              </Link>
            </div>
          : null
      }
    </div>
  }


  protected getNoEventsContent()
  {
    let heading = 'No Events Found';
    let text = 'No events have been created';

    // TODO: Switch out content depending on permission status
    return <div className="cell">
      <CtaPlaceholder
        icon={faCalendarAlt}
        heading={heading}
        text={text}
      />
    </div>
  }

  protected getEventCards(events: Array<Event>)
  {
    if (!events.length && this.state.eventsUpdating)
    {
      let eventPlaceholders = [1,2,3,4,5];

      return <div>
        {
          eventPlaceholders.map((eventPlaceholder: number, index: number) => {
            return <div
              className={[
                index == 0 ? 'mt-smo-2' : '',
                index == eventPlaceholders.length - 1 ? 'mb-smo-6' : ''
              ].join(' ')}
              key={index}
            >
              <EventCard
                index={index}
                name={null}
                startDate={null}
                invitedCount={null}
                goingCount={null}
                attendanceStatus={null}
                cta={null}
                updating={true}
                placeholder={true}
              />
              { index == eventPlaceholders.length - 1 ? '' : <div className="Hr mt-smo-4 mb-smo-4"/> }
            </div>
          })
        }
      </div>
    }

    return events.map((event: Event, index) =>
    {
      let attendance = this.state.attendanceByEventId[event.id] ? this.state.attendanceByEventId[event.id] : [];
      let going = attendance.filter((attendanceRow: Attendance) => {
        return attendanceRow.status == 'going';
      });
      let activeOrg = OrganisationStore.getActiveOrganisation();
      let userAttendanceRow = attendance.filter((attendanceRow: Attendance) => {
        return attendanceRow.userOrgId == activeOrg.userOrgId;
      });

      if (userAttendanceRow.length) userAttendanceRow = userAttendanceRow[0];
      let viewEventHref = '/events/' + event.id;

      let guestCount = EventGuestStore.getAllGuestsByEventId(event.id).length;

      return <div
        className={[
          index == 0 ? 'mt-smo-2' : '',
          index == events.length - 1 ? 'mb-smo-6 mb-md-1' : ''
        ].join(' ')}
        key={index}
      >
        <EventCard
          index={index}
          name={event.name}
          startDate={new Date(event.start)}
          invitedCount={attendance.length}
          goingCount={going.length}
          guestCount={guestCount}
          attendanceStatus={this.getStatusContent(userAttendanceRow)}
          cta={<Link to={viewEventHref}>
            <Button modifiers={['primary']}>View Event</Button>
          </Link>}
        />
        { index == events.length - 1 ? '' : <div className="Hr mt-smo-4 mb-smo-4"/> }
      </div>
    });
  }

  protected getTableControls(filteredDataLength: number)
  {
    let modifiers = [];

    if (!this.state.eventsUpdating && !this.state.events.length && !this.state.searchValue)
    {
      modifiers.push('Disabled');
    }

      return <PaginationControls
        start={this.getCurrentPageStartIndex(filteredDataLength) + 1}
        end={this.getCurrentPageEndIndex(filteredDataLength)}
        total={filteredDataLength}
        currentPage={this.state.currentPage}
        rowsPerPage={this.state.rowsPerPage}
        nextPage={this.nextPage.bind(this, filteredDataLength)}
        prevPage={this.prevPage.bind(this)}
        firstPage={this.firstPage.bind(this)}
        lastPage={this.lastPage.bind(this, filteredDataLength)}
        setRowsPerPage={this.setRowsPerPage.bind((this))}
        rowsPerPageOptions={this.state.rowsPerPageOptions}
        modifiers={modifiers}
      />
  }

  protected onSearch(value: string, item: Event)
  {
    let lowerCaseSearchValue = this.strip(value);

    return (
      value == ''
      || this.strip(item.name).indexOf(lowerCaseSearchValue) !== -1
    )
  }

  protected strip(string: string)
  {
    return string.toLowerCase().replace(' ', '');
  }

  protected onSearchChange(event)
  {
    this.setState({
      searchValue: event.target.value
    });
  }

  protected setSearchFieldDomNode(domNode)
  {
    this.searchFieldDomNode = domNode;
  }

  protected getStatusContent(attendance: Attendance)
  {
    if (!attendance)
    {
      return '';
    }

    switch (attendance.status)
    {
      case 'invited':
        return <div><FontAwesomeIcon icon={faEnvelopeOpenText} className={'mr-1'}/> Invited</div>;
      case 'going':
        return <div><FontAwesomeIcon icon={faCheckCircle} className={'mr-1'}/> Going</div>
    }
  }

  protected getShortMonth(date: Date)
  {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
    return monthNames[date.getMonth()];
  }

  public getEventStartDateDisplayValue(startDate: string)
  {
    if (!startDate)
    {
      return '';
    }

    let valueAsDate = new Date(startDate);

    let timeValue = startDate.split(' ')[1];
    let dateSection = DateInput.getDisplayValueFromDate(valueAsDate);

    let timeSection = TimeInput.getDisplayValueFromInputValue(timeValue);

    return dateSection + ' @ ' + timeSection;
  }

  /**
   * Pagination Controls
   */

  protected prevPage()
  {
    if (this.state.currentPage > 1)
    {
      this.setState({
        currentPage: (this.state.currentPage - 1)
      })
    }
  }

  protected nextPage(filteredDataLength: number)
  {
    let totalPages = Math.ceil(filteredDataLength / this.state.rowsPerPage);
    if (this.state.currentPage < totalPages && this.state.currentPage !== totalPages)
    {
      this.setState({
        currentPage: (this.state.currentPage + 1)
      })
    }
  }

  protected firstPage()
  {
    this.setState({
      currentPage: 1
    })
  }

  protected lastPage(filteredDataLength: number)
  {
    this.setState({
      currentPage: Math.ceil(filteredDataLength / this.state.rowsPerPage)
    })
  }

  protected setRowsPerPage(rowsPerPage: number)
  {
    return this.setState({
      rowsPerPage: rowsPerPage,
      currentPage: 1
    })
  }
}
